.header{
  height: 90px;
  width: 100%;
  display: flex;
  }
  .prof{
    font-size: 30px;
    position: relative;
    left: 110px;
    top: 24px;
  }
  .Kirish{
  height: 44px;
  width: 128px;
  border-radius: 100px;
  border: none;
  background-color:rgba(244, 244, 244, 1);
  position: relative;
  bottom: 12px;
  }
  .selekt{
    background-color:rgba(244, 244, 244, 1);
    color: blue;
  font-size: 15px;
  font-weight: 600;
  position: relative;
  bottom: 10px;
  }
  .selekkt{
    background-color:rgba(244, 244, 244, 1);
    padding-left: 30px;
  }
  .person{
  height: 38px;
  width: 38px;
  position: relative;
  top: 2px;
  right: 10px;
  }
  
 
  
 
  .biz{
    height: 210px;
  width: 1130px;
  border-radius: 16px;
  background: rgba(244, 244, 244, 1);
  margin-left: 105px;
  position: relative;
  top: 100px;
  }
  
 

  
 
 
 
  .lok{
    position: absolute;
    left: 470px;
  }
  .pro{
    font-size: 30px;
    position: relative;
    left:30px;
    top: 45px;
  }
  .xalq{
    height: 66px;
  width: 205px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(110, 115, 129, 1);
  position: relative;
  left: 30px;
  top: 60px;
  }
  .nav{
    display: grid;
    width: 104px;
    position: relative;
    bottom: 50px;
    left: 400px;
  }
  .navq{
    display: grid;
    width: 127px;
    position: relative;
    bottom: 50px;
    left: 400px;
  }
  .opa{
    width: 100px;
    height: 200px;
    position: absolute;
  }
  .orr{
    position: absolute;
width: 484px;
height: 532px;
background: #F4F4F4;
border-radius: 16px;
  }
  .oka{
    position:absolute;
    bottom: 1px;
    left: 40px;
  }
  .prok{
    position: absolute;
width: 100px;
height: 24px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.1px;
color: #FFA31A;
  }
 