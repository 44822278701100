*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
span{
  color: rgba(255, 169, 69, 1);
}
.a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.ol{
  display: flex;
  position: relative;
  left: 400px;
}
li{
  text-decoration: none;
   padding-left: 30px;
  list-style: none;
}
select{
  text-decoration: none;
  border: none;
  font-weight: bold;
}
h1{
  color: #fff;
  text-align: center;
  position: relative;
  top: 35px;
}
.b{
  text-decoration: none;
  color: rgba(28, 28, 28, 1);
}
.b1{
  text-decoration: none;
}
.l{
  display: flex;
  flex-direction: column;
  padding: 4px;
}
.l1{
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.flex{
  display: flex;
  gap: 70px;
}
.http{
  height: 25px;
width: 28px;

}
  
.instagram{
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  bottom: 45px;
  left: 200px;
}
.p1{
  width: 750px;
  position: absolute;
  left: 290px;
  top: 200px;
}

.malumotlar{
  width: 100%;
  height: 60px;
  background: #00BBC7;
}
.asosiy{
  position: absolute;
  left: 110px;
  top: 108px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.1px;
color: #FFFFFF;
opacity: 0.7;
}
/* yangilar */
aside{
  height: 667px;
  width: 1130px;
  margin-left: 105px;
  position: relative;
  top:50px;
}
/* resume */
.olo{
  position: absolute;
width: 285px;
height: 246px;
background: #F4F4F4;
border-radius: 10px;
}
.ui{
  display: grid;
  width: 260px;
 height: 220px;
 grid-template-columns: 266px;
grid-template-rows: 44px 44px 44px 44px 44px;
gap: 2px;
position: relative;
left: 9px;
top: 9px;
}
.ui div{
  border-radius: 6px;
}
.me{
  position: relative;
width: 116px;
left: 12px;
top: 10px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
letter-spacing: -0.1px;
color: #1C1C1C;
text-decoration: none;
}
.me1{
  position: relative;
width: 116px;
left: 12px;
top: 10px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
letter-spacing: -0.1px;
color: red;
text-decoration: none;
}
.ui div:nth-child(1):active{
  background-color: #00BBC7;
}
.ui div:nth-child(1){
  background-color: #E3E3E3;
}
/* resuzme */
.umu{
  position: absolute;
  width: 800px;
  height: 677px;
  left: 330px;
  top: 0px;
  background: #F4F4F4;
  border-radius: 10px;
}
.rim{
  position: absolute;
width: 198px;
height: 22px;
left: 30px;
top: 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
letter-spacing: -0.1px;
color: #000000;
}
.inp{
  display: grid;
  width: 515px;
  height: 100px;
  grid-template-columns: 251px 251px;
  grid-template-rows: 17px 17px;
  column-gap: 12px;
  row-gap: 63px;
  left: 30px;
  top: 70px;
  position: absolute;
}
.t{
  padding-left: 10px;
  height: 40px;
width: 251px;
  background: #FFFFFF;
border: 1px solid #E4E4E4;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
}
.maa{
  left: 30px;
  position: absolute;
  display: grid;
  width: 515px;
  height: 17px;
  grid-template-columns: 164px 164px 164px;
  grid-row: 17px;
  column-gap: 10px;
  top: 240px;
}
.q{
  padding-left: 10px;
  height: 40px;
width: 164px;
  background: #FFFFFF;
border: 1px solid #E4E4E4;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
}
.emil{
  position: absolute;
  left: 30px;
  top: 330px;
}
.xy{
  padding-left: 10px;
  position: absolute;
  left: 30px;
  top: 350px;
  width: 510px;
  height: 40px;
  background: #FFFFFF;
border: 1px solid #E4E4E4;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
}
.sss{
  height: 40px;
width: 96px;
position: absolute;
left: 30px;
top: 410px;
  background: #EC5147;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
}
.saq{
  position: absolute;
height: 22px;
left: 18px;
right: 17px;
top: calc(50% - 22px/2);
font-family: 'Inter';
font-size: 16px;
line-height: 22px;
text-align: center;
letter-spacing: -0.1px;
color: #FFFFFF;
text-decoration: none;
}
.parl{
  position: absolute;
width: 510px;
height: 144px;
left: 30px;
top: 490px;
border: 3px solid #E7E7E7;
border-radius: 8px;
}
.anii{
  position: absolute;
width: 149px;
height: 22px;
left: 15px;
top: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
letter-spacing: -0.1px;
color: #000000;
}
.ddd{
  display: grid;
width: 365px;
height: 17px;
grid-template-columns: 180px 180px;
grid-template-rows: 17px;
column-gap: 5px;
left: 15px;
position: absolute;
top: 50px;
}
.cl{
  padding-left: 10px;
  width: 180px;
  height: 40px;
  background: #FFFFFF;
border: 1px solid #E4E4E4;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
}
.rtyu{
  background: #A7AAAE;
box-shadow: 0px 1px 2px rgba(56, 56, 56, 0.07);
border-radius: 6px;
height: 40px;
width: 102px;
position: absolute;
left: 390px;
top: 67px;
}
.gfds{
  position: absolute;
height: 22px;
left: 21px;
right: 20px;
top: calc(50% - 22px/2);
text-decoration: none;
font-family: 'Inter';
font-size: 16px;
line-height: 22px;
text-align: center;
letter-spacing: -0.1px;
color: #FFFFFF;
}
.avar{
  position: absolute;
  left: 570px;
  top: 80px;
}